<template>
  <div>
    <div id="plan-pdf">
      <div
        :style="{
          height: getStorage('pdf_pageHeight'),
          backgroundColor: 'white',
        }"
        class="content onecon"
      >
        <div class="content_top"> 
          <img :src=" `${getStorage('ossurl')}${jhsinfo.applogo}?any_string_is_ok`" alt="" crossorigin="anonymous" >
          <!-- <img :src="nhapplogo" alt=""> -->
        </div>
        <div class="content_text">
          <div class="content_top_text">保险让生活更美好</div>
          <div class="content_bott_text">
            <div>专</div>
            <div>属</div>
            <div>保</div>
            <div>险</div>
            <div>计</div>
            <div>划</div>
          </div>
        </div>
        <div class="content_img">
          <div>{{ jhsinfo.cpname }}保障计划</div>
          <div>
            敬呈<span
              :class="{
                hast_name: `${jhsinfo.pidname.length}` != '0',
                not_name: `${jhsinfo.pidname.length}` == '0',
              }"
              >{{ jhsinfo.pidname }}</span
            >先生/女士 亲启
          </div>
        </div>
      </div>
      <div
        :style="{
          height: getStorage('pdf_pageHeight'),
          backgroundColor: 'white',
        }"
        class="content"
      >
        <div class="content_alltop">
          <div class="content_ttop">
            <img :src=" `${getStorage('ossurl')}${jhsinfo.applogo}?any_string_is_ok`" alt="" crossorigin="anonymous" >
          </div>
          <div>保险让生活更美好</div>
        </div>
        <div class="zx_text">
          您好，感谢您的信任与支持，根据您的目前状况和保障需求，特为您定制需求保障计划如下，请您参阅。
        </div>
        <div class="text_table">
          <div>保障计划概要</div>
          <table>
            <thead>
              <td>被保人</td>
              <td>投保人</td>
              <td>保障计划</td>
              <td>缴费期间</td>
              <td>保障额度</td>
              <td>首年保费</td>
            </thead>
            <tbody v-for="(item, index) in jhsinfo.xzlist" :key="index">
              <td>（{{ jhsinfo.pidsex }} {{ jhsinfo.pidage }}岁）</td>
              <td>（{{ jhsinfo.apidsex }} {{ jhsinfo.apidage }}岁）</td>
              <td>{{ item.prodname }}</td>
              <td>{{ item.yearnum }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.yearmoney }}元</td>
            </tbody>
            <tfoot>
              <td colspan="2">首年总保费</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ jhsinfo.khinfo.allmount }}元</td>
            </tfoot>
          </table>
        </div>
        <div class="szbj"></div>
        <!-- <div class="bzzr">保障责任详情如下：</div>
        <div
          class="xz_bzjh_tab"
          v-for="(item, index) in jhsinfo.pagingmap"
          :key="index"
        >
          <table>
            <tr>
              <td>{{ item[0].name }}</td>
              <td>
                <div v-for="(it, ind) in item[0].data" :key="ind">
                  <div>{{ it.liabdesc }}{{ it.liabexp }}起</div>
                  <div>{{ it.liabexplain }}</div>
                </div>
              </td>
            </tr>
          </table>
        </div> -->
      </div>
      <div
        v-for="(item, index) in pagingmap"
        :key="index"
        :style="{
          height: getStorage('pdf_pageHeight'),
          backgroundColor: 'white',
        }"
        class="content"
      >
        <div class="content_alltop">
          <div class="content_ttop">
            <img :src=" `${getStorage('ossurl')}${jhsinfo.applogo}?any_string_is_ok`" alt="" crossorigin="anonymous" >
          </div>
          <div>保险让生活更美好</div>
        </div>
        <div class="bzzr">保障责任详情如下：</div>
        <div class="xz_bzjh_tab " :class="(item.size <= 1 && index == pagingmap.length-1) ? '' :'szbj'">
          <table>
            <tr
              v-for="(it, ind) in item.data"
              :key="ind"
              v-if="it.data.length > 0"
            >
              <td>{{ it.name }}</td>
              <td>
                <div v-for="(ite, i) in it.data" :key="i">
                  <div class="ztfont">{{ ite.liabdesc }}</div>
                  <div>{{ ite.prodname }}</div>
                  <div>
                    {{
                       ite.liabexplain.length > 550
                        ? ite.liabexplain.substr(0, 550)
                        : ite.liabexplain
                    }}
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="fa_details szbj" v-if="item.size <= 1 && index == pagingmap.length-1">
          <div class="last_more_faxq">更多方案详情</div>
          <div class="ewm">
            <img :src="`${jhsinfo.ewmurl}?any_string_is_ok`" alt="" crossorigin="anonymous" />
            <div>请扫码查看更多方案详情</div>
          </div>
          <div class="fxinfo">
            <div>风险提示</div>
            <div>
              投保人在保单犹豫期后解除合同会遭受一定损失，具体保单利益请以保单合同为准。
            </div>
            <div>
              温馨提示：以上演示说明为本平台对上述产品的理解，便于保险从业人员学习、交流，演示数据仅供参考，请以实际为准。
            </div>
          </div>
        </div>
        <div class="szbj"></div>
      </div>
      <div
        v-if="isshow"
        :style="{
          height: getStorage('pdf_pageHeight'),
          backgroundColor: 'white',
        }"
        class="content"
      >
        <div class="content_alltop">
          <div class="content_ttop">
            <img :src=" `${getStorage('ossurl')}${jhsinfo.applogo}?any_string_is_ok`" alt="" crossorigin="anonymous" >
          </div>
          <div>保险让生活更美好</div>
        </div>
        <div class="last_more_faxq">更多方案详情</div>
        <div class="ewm">
          <img :src="`${jhsinfo.ewmurl}?any_string_is_ok`" alt="" crossorigin="anonymous" />
          <div>请扫码查看更多方案详情</div>
        </div>
        <div class="fxinfo">
          <div>风险提示</div>
          <div>
            投保人在保单犹豫期后解除合同会遭受一定损失，具体保单利益请以保单合同为准。
          </div>
          <div>
            温馨提示：以上演示说明为本平台对上述产品的理解，便于保险从业人员学习、交流，演示数据仅供参考，请以实际为准。
          </div>
        </div>
        <div class="szbj"></div>
      </div>
      <div
        :style="{
          height: getStorage('pdf_pageHeight'),
          backgroundColor: 'white',
        }"
        class="content"
      >
        <div class="content_alltop">
          <div class="content_ttop">
            <img :src=" `${getStorage('ossurl')}${jhsinfo.applogo}?any_string_is_ok`" alt="" crossorigin="anonymous" >
          </div>
          <div>保险让生活更美好</div>
        </div>
        <div class="content_fu">
          <div class="content_fufu">附：家庭风险建议</div>
          <div class="content_nr">
            保额足够
            一家之主的保障、配偶的保障、孩子的保障、父母的保障，不要让爱留有缺口
          </div>
          <div class="content_nr">
            保障全面
            意外、重疾、身故、子女教育、医疗、养老、财富传承，人生七张保单要买全
          </div>
          <div class="content_nr">
            年年检视
            家庭情况的变化，风险的变化，保单的变化要及时掌握，保单年检建议年年做
          </div>
          <div class="content_nr">
            加保及时
            家庭成员改变、财产状况改变、有意外或重疾事件发生，风险改变保障及时补充
          </div>
        </div>
        <div class="szbj"></div>
      </div>
      <div
        :style="{
          height: getStorage('pdf_pageHeight'),
          backgroundColor: 'white',
        }"
        class="content last_img"
      >
        <div class="content_alltop">
          <div class="content_ttop">
            <img :src=" `${getStorage('ossurl')}${jhsinfo.applogo}?any_string_is_ok`" alt="" crossorigin="anonymous" >
          </div>
          <div>保险让生活更美好</div>
        </div>
        <div class="first_text">
          <p>尊敬的客户：</p>
          <p class="formal">很荣幸成为您的专属保险师！</p>
          <p class="formal">希望通过我专业的服务，</p>
          <p class="formal">帮您完善家庭保障规划，</p>
          <p class="formal">让爱没有缺口！</p>
        </div>
        <div class="last_text">
          <p class="formal">客户经理：{{ jhsinfo.ywyinfo.cardempname }}</p>
          <p class="formal">联系电话：{{ jhsinfo.ywyinfo.cardmobile }}</p>
        </div>
        <!-- <div class="last_img"></div> -->
      </div>
    </div>
    <div
      :style="{ backgroundColor: getStorage('theme') }"
      class="content last_content"
      v-if="showdatapdf"
    >
      <div @click="great_pdf">点击获取PDF下载链接</div>
    </div>
    <md-popup v-model="revealshow" :mask-closable="closable">
      <div class="icdoling">
        <md-icon
          name="spinner"
          color="blue"
          size="lg"
          style="-webkit-filter: invert(1)"
        ></md-icon>
      </div>
    </md-popup>
  </div>
</template>
<script>
import {getStorage } from "@/lib/util";
import {  getPdfInfo,generatepdf } from "@/api/jhs/productlist/index";
import { Toast } from "mand-mobile";
export default {
  data() {
    return {
      closable: false,
      revealshow: false,
      empno: "",
      showdatapdf: true,
      jhsinfo: {
        xzlist: [],
        ywyinfo: [],
        khinfo: {},
      }, //计划书信息
      user: {},
      pagingmap: [],
      isshow: "",
      pdfimg:[],
      imgdata:[],
      nhapplogo:''
    };
  },
  created() {
    let data = {
      jhsno: this.$route.query.jhsno,
      reporturl: this.$route.query.reporturl,
    };
    getPdfInfo(data).then((res) => {
      this.jhsinfo = res.data.data;
      for (var obj in this.jhsinfo.pagingmap) {
        if (obj != 'true') {
          this.pagingmap.push(this.jhsinfo.pagingmap[obj]);
        }
        
      }
      console.log(this.pagingmap)
      this.pagingmap.forEach((item, index) => {
        if (item.size > 1) {
          this.isshow = true;
        } else {
          this.isshow = false;
        }
      });
    });
    this.user = getStorage("u_s", {});
  },
  methods: {
     // 将图片转成base64,解决合成时图片跨域问题

    // 生成pdf的事件
     async great_pdf() {
      this.revealshow = true;
      window.scrollTo(0, 0);
      this.showdatapdf = false;
      let data = {
        jhsno: this.$route.query.jhsno,
        reporturl: this.$route.query.reporturl,
      }
      generatepdf(data).then((res) => {
        this.$router.push({
          path: "/revealpdf",
          query: {
            file: getStorage('ossurl') + res.data.data,
          },
        });
        this.revealshow = false;
      })
    },
  },
};
</script>
<style lang="stylus" scoped>
.content {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.content_top {
  display: flex;
  padding: 0.5rem 0.5rem 0 0.5rem;

  img {
    width: 45%;
  }

  div {
    margin-left: 0.2rem;
    align-self: center;
  }
}

.content_text {
  .content_top_text {
    width: 80%;
    letter-spacing: 0.2rem;
    font-size: 0.8rem;
    color: #E26100;
    font-weight: 900;
    margin: 0 auto;
    margin-top: 3.5rem;
  }

  .content_bott_text {
    display: flex;
    width: 76%;
    justify-content: space-between;
    margin-left: 10%;
  }
}
.onecon {
  background-image: url('../../../../assets/jhs/img/pdf_bott.jpg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
.content_img {
  // background-image: url('../../../../assets/jhs/img/pdf_bott.jpg');
  width: 100%;
  height: 65%;
  // background-position: inherit;
  margin-top: 37%;
  // background-repeat: no-repeat;
  // background-size: 120% 120%;

  div:first-child {
    font-size: 0.6rem;
    font-weight: 900;
    letter-spacing: 0.05rem;
    width: 80%;
    margin-left: 10%;
    text-align: center;
  }

  div:nth-child(2) {
    font-size: 0.6rem;
    font-weight: 900;
    letter-spacing: 0.05rem;
    width: 90%;
    margin: .8rem auto 0;
    text-align: center;
    // text-align: center;
    // display: flex;
    // justify-content: center;
  }
}

.hast_name {
  // display: block;
  border-bottom: 0.07rem solid #000;
  font-size: 0.6rem;
}

.not_name {
  display: inline-block;
  width: 2.3rem;
  border-bottom: 0.07rem solid #000;
  font-size: 0.6rem;
}

.content_alltop {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;

  .content_ttop {
    display: flex;
    width: 50%;

    img {
      width: 50%;
    }

    div {
      align-self: center;
      margin-left: 0.2rem;
    }
  }

  >div:last-child {
    color: #EC6F34;
  }
}

tbody>td {
  line-height: 0.38rem;
}

.zx_text {
  font-size: 0.25rem;
  width: 90%;
  margin-left: 5%;
  background: red;
  padding: 0.2rem;
  color: #F9D4C8;
}

.content_fu {
  padding-left: 0.7rem;
  margin-top: 0.5rem;
  padding-right: 0.7rem;
}

.content_fufu {
  font-weight: 900;
  font-size: 0.45rem;
}

.content_nr {
  font-size: 0.3rem;
  margin-top: 0.25rem;
  margin-left: 0.45rem;
  font-weight: 300;
}

.content_nr:before {
  content: '';
  width: 0.2rem;
  height: 0.2rem;
  background-color: black;
  position: absolute;
  left: 1px;
  margin-left: 0.7rem;
  margin-top: 0.3rem;
  transform: rotate(45deg);
}

.first_text {
  margin-left: 15%;
  margin-top: 1.8rem;

  p:first-child {
    font-size: 0.5rem;
    font-weight: 900;
  }

  .formal {
    margin-top: 0.3rem;
  }
}

.last_text {
  margin-left: 15%;
  margin-top: 1.8rem;
}

.last_img {
  background-image: url('../../../../assets/jhs/img/pdf_last.jpg');
  width: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

table {
  border-top: 1px solid #999;
  border-left: 1px solid #999;
  border-spacing: 0;
  width: 100%;
  text-align: center;
  align-items: center;
  color: #929191;
  line-height: 0.6rem;
  margin-top: 0.2rem;

  tr {
    width: 100%;
  }
}

table td {
  padding: 0 0.01rem;
  border-bottom: 1px solid #999;
  border-right: 1px solid #999;
  font-size: 0.22rem;
}

thead td {
  font-weight: 900;
}

.text_table {
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  thead {
    >td:nth-child(3) {
      width: 3.3rem;
    }
  }

  >div {
    font-size: 0.5rem;
    font-weight: 900;
    margin-top: 0.6rem;
  }
}

.last_more_faxq {
  padding-left: 0.5rem;
  font-weight: 900;
}

.more_faxq {
  margin-top: 0.3rem;
  padding-left: 0.5rem;
  font-weight: 900;
}

.ewm {
  width: 25%;
  margin: 0 auto;

  div {
    font-size: 0.2rem;
    text-align: center;
    font-weight: 700;
  }
}

.fxinfo {
  padding: 0 0.5rem;
  margin-top: 0.3rem;

  >div:first-child {
    font-weight: 900;
  }

  >div:nth-child(2), >div:nth-child(3) {
    font-size: 0.3rem;
    font-weight: 900;
    line-height: 1.2;
    margin-top: 0.2rem;
    display: flex;
    color: red;
  }

  >div:nth-child(2):before, >div:nth-child(3):before {
    content: '*';
    color: #f00;
    font-size: 0.5rem;
    font-weight: 900;
    margin-right: 0.1rem;
    align-self: center;
  }
}

/deep/ .icdoling .md-icon {
  width: 1.42rem;
  height: 1.42rem;
  line-height: 1.42rem;
}

/deep/ .md-popup-mask {
  background-color: rgba(37, 38, 45, 0.9);
}

.last_content {
  background-color: #fff;
  height: 1rem;
  text-align: center;
  position: fixed;
  bottom: 0;
  box-shadow: 0 0 0.4rem #5D6061;

  div {
    line-height: 1rem;
    color: white;
    font-weight: 900;
  }
}

.xz_bzjh {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-top: 0.4rem;

  p:first-child {
    font-size: 0.5rem;
    font-weight: 900;
  }

  p:nth-child(2) {
    font-weight: 900;
    margin-top: 0.15rem;
  }

  .xz_bzjh_bzzr {
    font-size: 0.35rem;
    margin-top: 0.2rem;
  }
}

.xz_bzjh_tab {
  padding: 0 0.5rem;
  // background-image: url('~@/assets/jhs/img/wbg.png');
  // width: 100%;
  // height: 100%;
  // background-position: top;
  // background-repeat: no-repeat;
  // background-size: cover;

  tr {
    >td:first-child {
      width: 1rem;
    }

    >td:nth-child(2) {
      padding: 0.1rem 0.2rem;

      >div {
        >div:first-child {
          font-size: 0.3rem;
          text-align: left;
          font-weight: 900;
        }

        >div:nth-child(2) {
          font-size: 0.3rem;
          margin-bottom: 0.1rem;
          text-align: left;
          line-height: 0.38rem;
          color: #FED7A5;
        }

        >div:last-child {
          font-size: 0.3rem;
          margin-bottom: 0.1rem;
          text-align: left;
          line-height: 0.38rem;
        }
      }
    }
  }
}

.bzzr {
  font-size: 0.3rem;
  padding: 0 0.5rem;
  margin-top: 0.2rem;
  margin-bottom: -0.1rem;
  font-weight: 300;
}

.ztfont {
  color: black;
}

.fa_details {
  margin-top: 0.8rem;
}
.szbj {
  background-image: url('~@/assets/jhs/img/wbg.png');
  width: 100%;
  height: 100%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>