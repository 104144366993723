var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{attrs:{"id":"plan-pdf"}},[_c('div',{staticClass:"content onecon",style:({
        height: _vm.getStorage('pdf_pageHeight'),
        backgroundColor: 'white',
      })},[_c('div',{staticClass:"content_top"},[_c('img',{attrs:{"src":`${_vm.getStorage('ossurl')}${_vm.jhsinfo.applogo}?any_string_is_ok`,"alt":"","crossorigin":"anonymous"}})]),_vm._m(0),_c('div',{staticClass:"content_img"},[_c('div',[_vm._v(_vm._s(_vm.jhsinfo.cpname)+"保障计划")]),_c('div',[_vm._v(" 敬呈"),_c('span',{class:{
              hast_name: `${_vm.jhsinfo.pidname.length}` != '0',
              not_name: `${_vm.jhsinfo.pidname.length}` == '0',
            }},[_vm._v(_vm._s(_vm.jhsinfo.pidname))]),_vm._v("先生/女士 亲启 ")])])]),_c('div',{staticClass:"content",style:({
        height: _vm.getStorage('pdf_pageHeight'),
        backgroundColor: 'white',
      })},[_c('div',{staticClass:"content_alltop"},[_c('div',{staticClass:"content_ttop"},[_c('img',{attrs:{"src":`${_vm.getStorage('ossurl')}${_vm.jhsinfo.applogo}?any_string_is_ok`,"alt":"","crossorigin":"anonymous"}})]),_c('div',[_vm._v("保险让生活更美好")])]),_c('div',{staticClass:"zx_text"},[_vm._v(" 您好，感谢您的信任与支持，根据您的目前状况和保障需求，特为您定制需求保障计划如下，请您参阅。 ")]),_c('div',{staticClass:"text_table"},[_c('div',[_vm._v("保障计划概要")]),_c('table',[_vm._m(1),_vm._l((_vm.jhsinfo.xzlist),function(item,index){return _c('tbody',{key:index},[_c('td',[_vm._v("（"+_vm._s(_vm.jhsinfo.pidsex)+" "+_vm._s(_vm.jhsinfo.pidage)+"岁）")]),_c('td',[_vm._v("（"+_vm._s(_vm.jhsinfo.apidsex)+" "+_vm._s(_vm.jhsinfo.apidage)+"岁）")]),_c('td',[_vm._v(_vm._s(item.prodname))]),_c('td',[_vm._v(_vm._s(item.yearnum))]),_c('td',[_vm._v(_vm._s(item.amount))]),_c('td',[_vm._v(_vm._s(item.yearmoney)+"元")])])}),_c('tfoot',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("首年总保费")]),_c('td'),_c('td'),_c('td'),_c('td',[_vm._v(_vm._s(_vm.jhsinfo.khinfo.allmount)+"元")])])],2)]),_c('div',{staticClass:"szbj"})]),_vm._l((_vm.pagingmap),function(item,index){return _c('div',{key:index,staticClass:"content",style:({
        height: _vm.getStorage('pdf_pageHeight'),
        backgroundColor: 'white',
      })},[_c('div',{staticClass:"content_alltop"},[_c('div',{staticClass:"content_ttop"},[_c('img',{attrs:{"src":`${_vm.getStorage('ossurl')}${_vm.jhsinfo.applogo}?any_string_is_ok`,"alt":"","crossorigin":"anonymous"}})]),_c('div',[_vm._v("保险让生活更美好")])]),_c('div',{staticClass:"bzzr"},[_vm._v("保障责任详情如下：")]),_c('div',{staticClass:"xz_bzjh_tab",class:(item.size <= 1 && index == _vm.pagingmap.length-1) ? '' :'szbj'},[_c('table',_vm._l((item.data),function(it,ind){return (it.data.length > 0)?_c('tr',{key:ind},[_c('td',[_vm._v(_vm._s(it.name))]),_c('td',_vm._l((it.data),function(ite,i){return _c('div',{key:i},[_c('div',{staticClass:"ztfont"},[_vm._v(_vm._s(ite.liabdesc))]),_c('div',[_vm._v(_vm._s(ite.prodname))]),_c('div',[_vm._v(" "+_vm._s(ite.liabexplain.length > 550 ? ite.liabexplain.substr(0, 550) : ite.liabexplain)+" ")])])}),0)]):_vm._e()}),0)]),(item.size <= 1 && index == _vm.pagingmap.length-1)?_c('div',{staticClass:"fa_details szbj"},[_c('div',{staticClass:"last_more_faxq"},[_vm._v("更多方案详情")]),_c('div',{staticClass:"ewm"},[_c('img',{attrs:{"src":`${_vm.jhsinfo.ewmurl}?any_string_is_ok`,"alt":"","crossorigin":"anonymous"}}),_c('div',[_vm._v("请扫码查看更多方案详情")])]),_vm._m(2,true)]):_vm._e(),_c('div',{staticClass:"szbj"})])}),(_vm.isshow)?_c('div',{staticClass:"content",style:({
        height: _vm.getStorage('pdf_pageHeight'),
        backgroundColor: 'white',
      })},[_c('div',{staticClass:"content_alltop"},[_c('div',{staticClass:"content_ttop"},[_c('img',{attrs:{"src":`${_vm.getStorage('ossurl')}${_vm.jhsinfo.applogo}?any_string_is_ok`,"alt":"","crossorigin":"anonymous"}})]),_c('div',[_vm._v("保险让生活更美好")])]),_c('div',{staticClass:"last_more_faxq"},[_vm._v("更多方案详情")]),_c('div',{staticClass:"ewm"},[_c('img',{attrs:{"src":`${_vm.jhsinfo.ewmurl}?any_string_is_ok`,"alt":"","crossorigin":"anonymous"}}),_c('div',[_vm._v("请扫码查看更多方案详情")])]),_vm._m(3),_c('div',{staticClass:"szbj"})]):_vm._e(),_c('div',{staticClass:"content",style:({
        height: _vm.getStorage('pdf_pageHeight'),
        backgroundColor: 'white',
      })},[_c('div',{staticClass:"content_alltop"},[_c('div',{staticClass:"content_ttop"},[_c('img',{attrs:{"src":`${_vm.getStorage('ossurl')}${_vm.jhsinfo.applogo}?any_string_is_ok`,"alt":"","crossorigin":"anonymous"}})]),_c('div',[_vm._v("保险让生活更美好")])]),_vm._m(4),_c('div',{staticClass:"szbj"})]),_c('div',{staticClass:"content last_img",style:({
        height: _vm.getStorage('pdf_pageHeight'),
        backgroundColor: 'white',
      })},[_c('div',{staticClass:"content_alltop"},[_c('div',{staticClass:"content_ttop"},[_c('img',{attrs:{"src":`${_vm.getStorage('ossurl')}${_vm.jhsinfo.applogo}?any_string_is_ok`,"alt":"","crossorigin":"anonymous"}})]),_c('div',[_vm._v("保险让生活更美好")])]),_vm._m(5),_c('div',{staticClass:"last_text"},[_c('p',{staticClass:"formal"},[_vm._v("客户经理："+_vm._s(_vm.jhsinfo.ywyinfo.cardempname))]),_c('p',{staticClass:"formal"},[_vm._v("联系电话："+_vm._s(_vm.jhsinfo.ywyinfo.cardmobile))])])])],2),(_vm.showdatapdf)?_c('div',{staticClass:"content last_content",style:({ backgroundColor: _vm.getStorage('theme') })},[_c('div',{on:{"click":_vm.great_pdf}},[_vm._v("点击获取PDF下载链接")])]):_vm._e(),_c('md-popup',{attrs:{"mask-closable":_vm.closable},model:{value:(_vm.revealshow),callback:function ($$v) {_vm.revealshow=$$v},expression:"revealshow"}},[_c('div',{staticClass:"icdoling"},[_c('md-icon',{staticStyle:{"-webkit-filter":"invert(1)"},attrs:{"name":"spinner","color":"blue","size":"lg"}})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_text"},[_c('div',{staticClass:"content_top_text"},[_vm._v("保险让生活更美好")]),_c('div',{staticClass:"content_bott_text"},[_c('div',[_vm._v("专")]),_c('div',[_vm._v("属")]),_c('div',[_vm._v("保")]),_c('div',[_vm._v("险")]),_c('div',[_vm._v("计")]),_c('div',[_vm._v("划")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('td',[_vm._v("被保人")]),_c('td',[_vm._v("投保人")]),_c('td',[_vm._v("保障计划")]),_c('td',[_vm._v("缴费期间")]),_c('td',[_vm._v("保障额度")]),_c('td',[_vm._v("首年保费")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fxinfo"},[_c('div',[_vm._v("风险提示")]),_c('div',[_vm._v(" 投保人在保单犹豫期后解除合同会遭受一定损失，具体保单利益请以保单合同为准。 ")]),_c('div',[_vm._v(" 温馨提示：以上演示说明为本平台对上述产品的理解，便于保险从业人员学习、交流，演示数据仅供参考，请以实际为准。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fxinfo"},[_c('div',[_vm._v("风险提示")]),_c('div',[_vm._v(" 投保人在保单犹豫期后解除合同会遭受一定损失，具体保单利益请以保单合同为准。 ")]),_c('div',[_vm._v(" 温馨提示：以上演示说明为本平台对上述产品的理解，便于保险从业人员学习、交流，演示数据仅供参考，请以实际为准。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_fu"},[_c('div',{staticClass:"content_fufu"},[_vm._v("附：家庭风险建议")]),_c('div',{staticClass:"content_nr"},[_vm._v(" 保额足够 一家之主的保障、配偶的保障、孩子的保障、父母的保障，不要让爱留有缺口 ")]),_c('div',{staticClass:"content_nr"},[_vm._v(" 保障全面 意外、重疾、身故、子女教育、医疗、养老、财富传承，人生七张保单要买全 ")]),_c('div',{staticClass:"content_nr"},[_vm._v(" 年年检视 家庭情况的变化，风险的变化，保单的变化要及时掌握，保单年检建议年年做 ")]),_c('div',{staticClass:"content_nr"},[_vm._v(" 加保及时 家庭成员改变、财产状况改变、有意外或重疾事件发生，风险改变保障及时补充 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"first_text"},[_c('p',[_vm._v("尊敬的客户：")]),_c('p',{staticClass:"formal"},[_vm._v("很荣幸成为您的专属保险师！")]),_c('p',{staticClass:"formal"},[_vm._v("希望通过我专业的服务，")]),_c('p',{staticClass:"formal"},[_vm._v("帮您完善家庭保障规划，")]),_c('p',{staticClass:"formal"},[_vm._v("让爱没有缺口！")])])
}]

export { render, staticRenderFns }